import { Type } from 'class-transformer';
import { sumBy } from 'lodash';
import OrderObservationModel from '@/models/crm/order-observation.model';
import OrderItemModel from '@/models/crm/order-item.model';
import OrderFiscalDocumentModel from '@/models/crm/order-fiscal-document.model';
import OrderHistoryModel from '@/models/crm/order-history.model';

export default class OrderModel {
  codCliente!: string;

  codEmpresa!: string;

  codNotaFiscal!: string;

  codPedido!: string;

  @Type(() => Date)
  dataEmissao!: Date;

  @Type(() => Date)
  dataPrevFaturamento!: Date;

  idDataSource!: number;

  nomeDataSource!: string;

  situacao!: string;

  valorBruto!: number;

  valorLiquido!: number;

  valorSaldo!: number;

  qtdeCancelada!: number;

  valorDesconto?: number;

  cnpj?: string;

  inscricaoEstadual?: string;

  nomeCliente?: string;

  nomeRedespacho?: string;

  nomeRepres?: string;

  nomeTransp?: string;

  observacao?: string;

  percComissao?: number;

  percDesconto1?: number;

  tabPreco?: string;

  tipoFreteTransp?: string;

  tipoNota?: string;

  uf?: string;

  @Type(() => OrderHistoryModel)
  historico?: OrderHistoryModel[];

  @Type(() => OrderItemModel)
  itens!: OrderItemModel[];

  @Type(() => OrderObservationModel)
  observacoes!: OrderObservationModel[];

  @Type(() => OrderFiscalDocumentModel)
  notasFiscais!: OrderFiscalDocumentModel[];

  coringa1?: string;

  get qtdeItens(): number {
    return sumBy(this.itens || [], 'qtdePedida');
  }

  get qtdeItensCancelados(): number {
    return sumBy(this.itens || [], 'qtdeCancelado');
  }

  get qtdeItensFaturados(): number {
    return sumBy(this.itens || [], 'qtdeFaturado');
  }

  get totalBrutoItens(): number {
    return sumBy(this.itens || [], ((i) => (i.valorUnitarioBruto * i.qtdePedida)));
  }

  get totalLiquidoItens(): number {
    return sumBy(this.itens || [], ((i) => (i.valorUnitarioLiquido * i.qtdePedida)));
  }

  get totalSaldoItens(): number {
    return sumBy(this.itens || [], 'totalSaldo');
  }

  get totalFaturadoItens(): number {
    return sumBy(this.itens || [], 'totalFaturado');
  }

  get totalItens(): number {
    return sumBy(this.itens || [], 'valorTotal');
  }
}
